import React from 'react';
import styled from 'styled-components';
import { Colors } from '../utils/constants';
import { Link } from 'gatsby';

const CareerComponent = ({
    className,
    to,
    desc,
    title
  }) => {
    return (
      <Link as="div" className={className} to={to}>
        <div className="text-group">
            <div className='title'>
                {title}
            </div>
            <div className='desc'>
                {desc}
            </div>
            <div className='arrow'>
                READ MORE
            </div>
        </div>
      </Link>
    )
  };

export default styled(CareerComponent)`

display: block;
position: relative;
padding: 32px 48px;
text-decoration: none;
transition: all 200ms ease-in-out;
border-bottom: 2px solid #E3E3E3;
background: #f7f7f7;

:hover {
  padding-left: 68px;
  padding-right: 28px;
  color: ${Colors.EYA_RED};
  background: #FFF;
  .desc{
      color: #000;
  }
}

.text-group{
    text-align: left;
    .title{
        font-weight: bold;
        margin-bottom: 10px;
    }
    .desc{
        max-width: 460px;
        font-size: 15px;
        line-height: 23px;
        color: #8E8E8E;
        margin-right: 180px;

        @media screen and (max-width: 768px) {
            margin-right: 0;
        }
    }
    .arrow{
        position: absolute;
        top: calc(50% - 10px);
        right: 64px;
        color:#666;
        font-size: 12px;
        letter-spacing: 1px;
        :after{
            position: relative;
            top: 6px;
            content: '>';
            font-size: 32px;
            color: #999;
            margin-left: 24px;
            line-height: 0;
        }
        :hover{
            color: ${Colors.EYA_RED};
        }
        @media screen and (max-width: 768px) {
            display: none;
        }
    }
}

`;