import React from "react";
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-width: 328px;
    max-width: 1199.98px;
    word-spacing: 2px;
    
    @media screen and (max-width: 768px) {
        padding: 10px;
        flex-direction: column;
    }
`;

const Left = styled.div`
    width: 40%;
    text-align: center;

    @media screen and (max-width: 768px) {
        width: 100%;
    }
`;

const Right = styled.div`
    width: 40%;
    margin-left: 5%;
    text-align: left;

    @media screen and (max-width: 768px) {
        text-align: center;
        width: 100%;
    }
`;

const ThumbnailImage = styled.img`
    width: 170px;
    height: 170px;
    border-radius: 50%;
    object-fit: cover;
    margin-top: 50px;

`;

function CardWithImage(props) {
    return (
        <Container className={props.className}>
            <Left className='left'>
                <ThumbnailImage alt='' src={props.img} />
            </Left>
            <Right className='right'>
                <div className='title'>
                    {props.title}
                </div>
                <div className='description'>
                    {props.description}
                </div>
            </Right>
        </Container>
      );
}

export default styled(CardWithImage)`
    padding-bottom: 20px;
    transition: all .5s ease-in-out;
    img{
        max-width: none;
    }
    .title {
        font-weight: 700;
        font-size: 35px;
        margin-bottom: 10px;
    }
    .description{
        color: #7e7e7e;
        font-size: 20px;
        line-height: 30px;
        font-weight: 300;
    }

`;