import React from 'react';
import PageLayout from '../components/PageLayout';
import CardWithImage from "../components/CardWithImage";
import CareerComponent from "../components/CareerComponent";
import styled from 'styled-components';
import { graphql } from 'gatsby';

const benefits = [
  {
    title: 'Enhance Your Art Career',
    image: 'https://images.unsplash.com/photo-1499781350541-7783f6c6a0c8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1715&q=80',
    description: 'You can get a proof of volunteers from a non-profit organization registered in Ontario. You will have opportunities to participate in EYA projects and activities for free. '
  },
  {
    title: 'Gain Valuable Knowledge',
    image: 'https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80',
    description: "Develop your art management skills and the ability to engage with our partners. Experience event planning, propaganda campaign and teamwork. Have opportunities to join an international curatorial team.",
  },
  {
    title: 'Make Wonderful Connections',
    image: 'https://images.unsplash.com/photo-1549476923-1afbc530eee9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1334&q=80',
    description: "Have opportunities to meet emerging artists from different races in Toronto. Have opportunities to work with ROM one of the Ontario's top art venue. Last but not least, you will meet like-minded team in EYA.",
  },
];

const Benefits = styled.div`
  margin-bottom: 40px;
`;

const CareersList = styled.div`
  max-width: 1142px;
  margin-left: auto;
  margin-right: auto;
  background: #FFF;
`;

function JoinUs(props) {
  function renderBenefits(benefitsList){
    return (
      benefitsList.map( (item, index) => {
        return (
            <CardWithImage 
              key = {index}
              title = {item.title}
              img = {item.image}
              description = {item.description}
              className={`animated fadeInLeft ${index !== 0 ? `delay-${index}s` : ''}`}
            ></CardWithImage>
        )
      })
    );
  }

  const renderCareers = () => {

    const notHiddenEdges = props.data.allMdx.edges.filter(edge => !edge.node.frontmatter.hidden);

    return (
      notHiddenEdges.map((edge, index) => {
        const { node } = edge;
        const { id, name, description} = node.frontmatter;
        const otherProps = {
          key: id,
          title: name.toUpperCase(),
          to: `/joinus/${id}`
        }
        return (
              <CareerComponent 
                key={index}
                to={otherProps.to}
                desc={description}
                title={name}
              >
              </CareerComponent>
        )
      })
    )
  }

  return (
    <PageLayout title="JOIN US" uppercaseTitle titleAnim>
      <div className={props.className}>
        <div className="main-body">
          <div className="text-area">
            <Benefits>
                {renderBenefits(benefits)}
            </Benefits>
            <CareersList>
              {renderCareers()}
            </CareersList>
          </div>
        </div>
      </div>
    </PageLayout>
  )
}

export default styled(JoinUs)`
margin-top: -20px;

@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-10%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-10%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInLeft {
  animation-name: fadeInLeft;
}

.animated {
  animation-duration: .5s;
  animation-fill-mode: both;
}

.animated.delay-1s {
  animation-delay: 1s;
}

.animated.delay-2s {
  animation-delay: 2s;
}

.animated.delay-3s {
  animation-delay: 3s;
}

`;

export const query = graphql`
  query {
    allMdx(
      sort: { order: DESC, fields: [frontmatter___time] }
      filter: {fileAbsolutePath: {regex: "/(/careers/)/"}}
    ) {
      edges {
        node{
          frontmatter {
            id
            hidden
            name
            description
          }
        }
      }
    }
  }`;